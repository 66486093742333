<template>
  <b-card>
    <div class="row">
      <input-text
        id="input-codigo"
        class="col-12 col-md-2"
        :label="$t('ASSINATURAS.CODIGO')"
        :disabled="form.id"
        v-model="form.codigo"
        ref="form-codigo"
        required
      />
      <input-text
        id="input-plano"
        class="col-12 col-md-8"
        :label="$t('ASSINATURAS.PLANO')"
        :disabled="form.id"
        v-model="form.nome"
        ref="form-plano"
        required
      />
      <input-text
        id="input-frequencia"
        class="col-12 col-md-2"
        :label="$t('ASSINATURAS.FREQUENCIA')"
        disabled
        v-model="form.frequencia"
        ref="form-frequencia"
        required
      />
      <input-text
        id="input-parcelas"
        class="col-12 col-md-2"
        :label="$t('ASSINATURAS.QUANTIDADE_COBRANCAS')"
        readonly
        v-model="form.quantidadeCobranca"
        ref="form-numero-cobranca"
        required
      />
      <input-currency
        id="input-valor"
        class="col-12 col-md-2"
        :label="$t('ASSINATURAS.VALOR')"
        readonly
        v-model="form.valor"
        ref="form-valor"
        required
      />
      <input-text
        id="input-situacao"
        class="col-12 col-md-2"
        :label="$t('ASSINATURAS.SITUACAO')"
        disabled
        v-model="form.situacao"
        ref="form-situacao"
        required
      />
      <input-date
        id="input-data-inicio"
        :class="`col-12 col-md-${!form.id ? 3 : 2}`"
        :disabled="form.id"
        :label="$t('ASSINATURAS.DATA_INICIO')"
        v-model="form.dataInicio"
        ref="form-data-inicio"
        required
      />
      <input-date
        id="input-data-termino"
        :class="`col-12 col-md-${!form.id ? 3 : 2}`"
        :label="$t('ASSINATURAS.DATA_TERMINO')"
        disabled
        v-model="form.dataTermino"
        ref="form-data-termino"
        required
      />
      <div v-if="form.id" class="col-12 col-md-2 p-mb-label">
        <b-button
          id="btn-cancelar-assinatura"
          class="w-100 mb-3"
          :disabled="form.situacao != 'Ativa' && form.situacao != 'Em Atraso'"
          variant="outline-valorem-vermelho"
          @click="abrirCancelamento"
        >
          {{ $t("ASSINATURAS.CANCELAR_ASSINATURA") }}
        </b-button>
      </div>
      <div class="col-12 mb-3 d-flex align-items-center">
        <span class="mr-2">{{ $t("ASSINATURAS.DETALHES_ASSINANTE") }}</span>
        <hr class="flex-fill" />
      </div>
      <input-text
        id="input-nome-assinante"
        :class="`col-12 col-md-4`"
        :label="$t('ASSINATURAS.NOME_ASSINANTE')"
        :disabled="form.id"
        v-model="form.nomeAssinante"
        ref="form-nome-assinante"
        required
      />
      <input-text
        id="input-email-assinante"
        :class="`col-12 col-md-${!form.id ? 4 : 3}`"
        :label="$t('ASSINATURAS.EMAIL_ASSINANTE')"
        :disabled="form.id"
        v-model="form.emailAssinante"
        ref="form-email-assinante"
        required
      />
      <input-text
        id="input-cartao-assinante"
        :class="`col-12 col-md-${!form.id ? 4 : 3}`"
        :label="$t('ASSINATURAS.CARTAO_ASSINANTE')"
        :disabled="form.id"
        v-model="form.cartaoAssinante"
        :mask="'#### #### #### ####'"
        ref="form-cartao-assinante"
        required
      />
      <div v-if="form.id" class="col-12 col-md-2 p-mb-label">
        <b-button
          id="btn-alterar-cartao"
          class="w-100 mb-3"
          :disabled="form.situacao != 'Ativa' && form.situacao != 'Em Atraso'"
          variant="outline-valorem-ciano"
          @click="$emit('alterarCartao')"
        >
          {{ $t("ASSINATURAS.ALTERAR_CARTAO") }}
        </b-button>
      </div>
      <modal-cancelamento ref="modal" />
    </div>
  </b-card>
</template>
<script>
import { InputText, InputCurrency, InputDate } from "@/components/inputs";
import helpers from "@/common/utils/helpers";
import PlanosService from "@/common/services/planos/planos.service";
import ModalCancelamento from "./ModalCancelamentoAssinatura";
export default {
  props: {
    form: {
      required: true,
    },
  },
  components: {
    InputText,
    InputCurrency,
    InputDate,
    // InputMask,
    ModalCancelamento,
  },
  data() {
    return {
      colunasProduto: [
        { key: "nome", label: this.$t("PLANOS.NOME_PRODUTO"), thClass: "w-50" },
        {
          key: "valor",
          label: this.$t("PLANOS.VALOR_PRODUTO"),
          formatter: (v) => {
            if (v >= 0) return helpers.formatarValor(v);
          },
        },
        { key: "quantidade", label: this.$t("PLANOS.QUANTIDADE_PRODUTO") },
      ],
      plano: null,
    };
  },
  methods: {
    abrirCancelamento: function () {
      this.$refs.modal.abrirModal(this.form.id);
    },
    buscarPlano: function (id) {
      PlanosService.buscar(id).then((res) => {
        let plano = res.data.data;
        this.plano = plano;
        this.$emit("emitir-plano", this.plano);

        this.form.frequencia = plano.frequencia;

        // let valor = 0;
        // /*if(plano.aplicarTaxaExtra)
        //     valor = plano.valorTaxa;*/

        // valor += plano.produtos.reduce((total, el) => { return total + (el.valor * el.quantidade)},0)
        // this.form.valor = helpers.formatarValor(valor / plano.quantidadeCobranca);

        this.form.carencia = 0;
        if (plano.aplicarCarenciaPlano) this.form.carencia = plano.carencia;

        if (this.form.dataInicio) this.alterarDataTermino(this.form.dataInicio);

        this.$watch("form.dataInicio", this.alterarDataTermino);
      });
    },
    alterarDataTermino: function (valor) {
      if (valor) {
        switch (this.form.frequencia) {
          case "Mensal":
            this.form.dataTermino = helpers.adicionarData(
              valor,
              this.form.quantidadeCobranca,
              "month"
            );
            break;
          case "Semestral":
            this.form.dataTermino = helpers.adicionarData(
              valor,
              this.form.quantidadeCobranca,
              "month"
            );
            break;
          case "Anual":
            this.form.dataTermino = helpers.adicionarData(
              valor,
              this.form.quantidadeCobranca,
              "year"
            );
            break;
        }
      } else {
        this.form.dataTermino = null;
      }
    },
  },
  computed: {
    // bloquearCancelar: function () {
    //   return this.form.situacao != "Ativa" || this.form.situacao == "EmAtraso";
    // },
    tabelaProdutos: function () {
      if (!this.plano) return [];

      if (!this.plano.aplicarTaxaExtra) return this.plano.produtos;

      return [
        ...this.plano.produtos,
        {
          produtoId: "-",
          nome: `(Taxa extra) - ${
            this.plano.descricaoTaxaExtra || "Sem descrição"
          } - ${
            this.parcelas.find((el) => el.value == this.plano.parcelas)?.text ||
            ""
          }`,
          quantidade: 1,
          valor: helpers.removerValor(`${this.plano.valorTaxa}`) || 0,
        },
      ];
    },
    valorTotal: function () {
      let total = this.tabelaProdutos.reduce((total, produto) => {
        return total + produto.valor * produto.quantidade;
      }, 0);
      return helpers.formatarValor(total);
    },
    parcelas: function () {
      if (!this.plano) return [];

      let parcelas = [
        {
          value: 1,
          text: "À Vista",
        },
      ];
      for (let i = 2; i <= this.plano.quantidadeCobranca; i++) {
        let valor = helpers.removerValor(`${this.plano.valorTaxa}`);
        parcelas.push({
          value: i,
          text: `Parcelado em ${i} vezes de ${helpers.formatarValor(
            valor / i
          )}`,
        });
      }
      return parcelas;
    },
    permissao: function () {
      return helpers.validarAcesso("Assinatura");
    },
  },
  mounted() {},
  watch: {
    "form.planoId": function () {
      this.buscarPlano(this.form.planoId);
    },
  },
};
</script>
<style lang=""></style>
